/*eslint no-unused-vars: ["error", { "vars": "local" }]*/

import "./fonts/arial/stylesheet.css"
import "./f3cc.css"

import "./main.scss"

import _throttle from "lodash/throttle"
import "blissfuljs/bliss.shy.min.js"
const $ = window.Bliss
// const $$ = $.$

import FocusAreaTabs from "./js/FocusAreaTabs.js"
import FooterContactForm from "./js/FooterContactForm.js"
import Startscreen from "./js/Startscreen.js"
import TopicFilter from "./js/TopicFilter.js"

$.ready().then(() => {
  /*
   *   Footer contact form
   */
  const footerContactForm = $("[data-footer-contact-form]")

  if (footerContactForm !== null) {
    new FooterContactForm(footerContactForm)
  }

  /*
   *   Startscreen
   */
  const startscreen = $(".startscreen")

  if (startscreen !== null) {
    new Startscreen(startscreen)
  }

  /*
   *   Topic / Focus Area Filter
   */
  const topicFilter = $("[data-topic-filter]")

  if (topicFilter !== null) {
    new TopicFilter(topicFilter)
  }

  /*
   *   Topic / Focus Area Filter
   */

  const focusAreaTabList = $("[data-fa-tabs]")

  if (focusAreaTabList !== null) {
    new FocusAreaTabs(focusAreaTabList)
  }

  /*
   *   Navigation
   */
  const navigation = $("[data-navigation]")

  if (navigation !== null) {
    const toggle = document.querySelector("[data-navigation-toggle]")

    toggle.addEventListener("click", (e) => {
      e.preventDefault()
      document.body.classList.toggle("expanded-nav")
    })
  }

  /*
   *   Set scroll indicator
   */

  const scrolledThresholdElement = $("[data-scrolled-threshold]")
  let scrolledThreshold = 80

  if (scrolledThresholdElement !== null) {
    scrolledThreshold += scrolledThresholdElement.offsetHeight
  }

  window.addEventListener(
    "scroll",
    _throttle(() => {
      if (window.pageYOffset > scrolledThreshold) {
        document.body.classList.add("scrolled")
      } else {
        document.body.classList.remove("scrolled")
      }
    }, 100),
  )

  window.addEventListener("click", (e) => {
    if (e.target.matches("[data-popup]")) {
      e.preventDefault()
      window.open(e.target.href, "popup", "width=500,height=700")
    }
  })
})
